<template>
  <div>
    <div v-for="(dItem,dIndex) in detailList" :key="dIndex" class="info-box">
      <div class="title">{{ dItem.pay_period || dIndex+1 }}/2</div>
      <ul class="list-box">
        <li
          v-for="(item,index) in dataList"
          :key="index"
          :class="{'select-box':item.type==='select','is-hide':isHide(dItem,item) || (!dItem.isToggleShow&&index>2),'select-box-item':index>2}"
          class="list-item"
        >
          <div v-if="item.type==='select'" :class="{'is-show-select':dItem.isToggleShow}" class="select-btn" @click="toggleShow(dItem)" />
          <show-plan v-else-if="item.type==='showPlan'" ref="showPlan" :data-form="dItem" />
          <div v-else class="clearfix">
            <span class="item-title">{{ item.name }}</span>
            <span class="item-cont"> {{ item.isRed&&item.key!=='Ukucinja_kwakulekelesha' ? ' PHP ' : '' }} {{ dItem[item.key] }} {{ item.key==='Ukucinja_kwakulekelesha' ? item.unit(dItem[item.key]*1) : '' }}</span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import ShowPlan from './ShowPlan'
import { payInfo } from '@/api/axios/index'
export default {
  name: 'MouldInfo',
  components: { ShowPlan },
  props: {
    dataList: {
      type: Array,
      default: () => {
        return []
      }
    },
    title: {
      type: String,
      default: ''
    },
    yellowBg: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isToggleShow: false,
      detailList: []
    }
  },
  computed: {
    isHide() {
      return function(dItem, item) {
        let flag = false
        // if (!item.val) return
        // const hasVal = item.val.split('$')[1] || item.val
        // return item.isRed && !hasVal
        if (dItem[item.key] + '' === '0')flag = true
        return flag
      }
    }
  },
  created() {
    this.payInfo()
  },
  methods: {
    handleClick() {
      this.$emit('iconClick')
    },

    toggleShow(dItem) {
      dItem.isToggleShow = !dItem.isToggleShow
    },

    // 详情
    async payInfo() {
      try {
        const { data } = await payInfo()
        // console.log(data)
        if (data?.Ukubwekeshapo_ukwingila.length) {
          data.Ukubwekeshapo_ukwingila.forEach((item,index) => {
            console.log(item,'itemitemitem');
            item.isToggleShow = false
            item.one_pay_amount = data.Ukubwekeshapo_ukwingila[0].ukufumyaIfingi
            item.one_repay_time = data.Ukubwekeshapo_ukwingila[0].UkucinjaKwaku
            item.two_pay_amount = data.Ukubwekeshapo_ukwingila[1].ukufumyaIfingi
            item.two_repay_time = data.Ukubwekeshapo_ukwingila[1].UkucinjaKwaku
            item.one_calculate_way = data.Ukubwekeshapo_ukwingila[0].des
            item.two_calculate_way = data.Ukubwekeshapo_ukwingila[1].des
            if(index==0){
              item.payment_amount = data.Ukufumya_fumya
            }else{
              item.payment_amount = item.ukufumyaIfingi
            }
            
            // item.payment_amount = item.ukufumyaIfingi
            Object.keys(data).forEach(items => {
              if (items !== 'UkucinjaKwaku') {
                item[items] = data[items]
              }
            })
          })
          this.detailList = data?.Ukubwekeshapo_ukwingila || []
        }

        console.log(this.detailList)
      } catch (err) {
        console.log(err)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.info-box{
  background: #FFFFFF;
  border-radius: 24px;
  border: 2px solid #CCCCCC;
  padding: 24px 40px;
  transition: height .5s;
  margin-bottom: 24px;
  .title{
    height: 52px;
    font-size: 36px;
    font-weight: bold;
    color: #000000;
    line-height: 52px;
    margin-bottom: 24px;
  }
  .list-box{
    .list-item{
      // height: 40px;
      font-size: 28px;
      color: #CCCCCC;
      line-height: 40px;
      margin-bottom: 16px;
      .select-btn{
        height: 44px;
        margin-top: 16px;
        background: url('~@/assets/images/stayPay/showall-icon.png') no-repeat center center;
        background-size: 44px;
      }
      .is-show-select.select-btn{
        background: url('~@/assets/images/stayPay/show-select-icon.png') no-repeat center center;
        background-size: 44px;
      }
      span{
        float: left;
      }
      .item-title{
        font-size: 28px;
        color: #CCCCCC;
      }
      .item-cont{
        float: right;
        font-size: 28px;
        color: #000000;
      }
    }
    .is-hide{
      display: none;
    }
  }
}
.select-box-item .item-cont{
  font-size: 28px;
  font-weight: 500;
  color: #000000;
}
</style>

