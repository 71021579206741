<template>
  <div>
    <base-template>
      <mould-info id="mainBox1" ref="loanMoneyInfo" class="loan-money" :data-list="loanMoneyInfoList" @iconClick="handleClick" />
    </base-template>
  </div>
</template>

<script>
import MouldInfo from './components/mouldInfo.vue'

export default {
  components: { MouldInfo },
  data() {
    return {
      priceVisible: false,
      dataForm: JSON.parse(localStorage.getItem('payDetailData')),
      loanMoneyInfoList: [
        { name: this.$t('baseInfo.shouldPay') + '(PHP)', key: 'payment_amount', val: '', unit: '' },
        { name: this.$t('baseInfo.paymentDate'), key: 'UkucinjaKwaku', val: '', unit: '$' },
        { type: 'select' },
        { name: this.$t('baseInfo.userName'), key: 'Full_name', val: '', unit: '' },
        { name: this.$t('baseInfo.loadPrice') + '(PHP)', key: 'IfilemboFibuuteSaana', val: '', unit: '$' },
        { name: this.$t('baseInfo.loadDate'), key: 'ukupayment_date', val: '', unit: '' },
        { type: 'showPlan', list: [] },
        { name: this.$t('baseInfo.overdueDay'), isRed: true, key: 'Ukucinja_kwakulekelesha', val: '', unit: (val) => this.$t('baseInfo.days', { day: val }), after: true },
        { name: this.$t('baseInfo.overdueInterest'), isRed: true, key: 'UkufisaNokutaipa', val: '', unit: 'PHP' },
        { name: this.$t('baseInfo.overdueDamages'), isRed: true, key: 'UkufumyapoWei', val: '', unit: 'PHP' }
      ]
    }
  },
  computed: {
    bankAccount() {
      return this.dataForm.bank_account.replace(/\D/g, '').replace(/....(?!$)/g, '$& ')
    }
  },
  created() {
    // this.getBillDetails()
    this.$H5Object.changeStatusBarBgColor('#F4F5FC')
    this.$H5Object.isShowBottomTabinter(false)
    this.$H5Object.changeStatusIsDarkFont(true)
  },
  methods: {
    getBillDetails() {
      Object.keys(this.dataForm).forEach(item => {
        this.setLoanMoneyInfo(this.dataForm, item)
      })
    },

    setRequestData() {
      const { order_id, order_child_id } = this.$route.query
      return { order_id: order_id, order_child_id: order_child_id }
    },

    // 设置借款信息
    setLoanMoneyInfo(data, key) {
      this.loanMoneyInfoList.forEach(item => {
        if (item.key === key) {
          const dataVal = data[key]
          if (dataVal) {
            item.after ? item.val = dataVal + item.unit : item.val = item.unit + dataVal
          }
        }
      })
    },

    // 设置还款信息
    setPayMoneyInfo(data, key) {
      this.payMoneyInfoList.forEach(item => {
        if (item.key === key) {
          const dataVal = data[key]
          if (dataVal) {
            item.after ? item.val = dataVal + item.unit : item.val = item.unit + dataVal
          }
        }
      })
    },
    handleClick() {
      this.priceVisible = true
      this.$nextTick(() => {
        this.$refs.priceDialog.init()
      })
    }
  }
}
</script>
<style lang="scss" scoped>

:deep(.is-content){
  padding-top: 40px;
  padding-bottom: 64px;
}
</style>
