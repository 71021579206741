<template>
  <div class="show-plan">
    <!-- <div class="plan-title">
      <span class="title"> {{ $t('userCenter.firstPayDay') }}</span>
      <span class="cont">{{ dataForm.one_repay_period }}{{ $t('baseInfo.days',{day:dataForm.one_repay_period*1}) }}</span>
    </div> -->
    <div class="plan-list-box">
      <span class="step"><em /></span>
      <div class="plan-list-item">
        <span class="plan-price">PHP {{ dataForm.one_pay_amount | toThousandFilter }} <span>1st</span></span>
        <span class="plan-date">{{ dataForm.one_repay_time }}</span>
      </div>
      <p>{{ dataForm.one_calculate_way }}</p>
    </div>
    <div class="plan-list-box">
      <span class="step"><em /></span>
      <div class="plan-list-item">
        <span class="plan-price">PHP {{ dataForm.two_pay_amount |toThousandFilter }} <span>2nd</span></span>
        <span class="plan-date">{{ dataForm.two_repay_time }}</span>
      </div>
      <p class="calculate-way">{{ dataForm.two_calculate_way }}</p>
      <p class="pay-detail-tips">{{ $t('userCenter.payDetailTips') }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dataForm: {
      type: [Object, null],
      default: () => {
        return {
          one_pay_amount: '',
          one_repay_time: '',
          one_calculate_way: '',
          two_pay_amount: '',
          two_repay_time: '',
          two_calculate_way: ''
        }
      }
    }
  },
  data() {
    return {

    }
  }
}
</script>
<style lang="scss" scoped>
.show-plan{
  background: #F7F8FC;
  border-radius: 24px;
  padding: 32px;
  .plan-title{
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
    span{
      font-size: 28px;
      color: #005FE9;
    }
    .cont{
      font-size: 28px;
      font-weight: 500;
      color: #000000;
    }
  }
}
.plan-list-box{
  padding-left: 40px;
  position: relative;
  margin-bottom: 48px;
  .step{
    position: absolute;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    top: 8px;
    left: 0;
    border: 2px solid #005FE9;
    em{
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
      &::before{
        content:'';
        position: absolute;
        width: 13px;
        height: 13px;
        border-radius: 50%;
        left: 50%;
        top: 50%;
        margin-left: -6.5px;
        margin-top: -6.5px;
        background: #005FE9;
      }
      &::after{
        content:'';
        position: absolute;
        width: 2px;
        height: 122px;
        left: 9px;
        top: 22px;
        background: #005FE9FF;
      }

    }
  }
  .plan-list-item{
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    .plan-price{
      font-size: 28px;
      font-weight: bold;
      color: #000000;
      span{
        background: rgba(0,95,233,0.1);
        border-radius: 8px;
        padding: 0 8px;
        font-size: 24px;
        font-weight: 400;
        color: #005FE9;
      }
    }
    .plan-date{
      font-size: 24px;
      color: #F5452B;
    }
  }
  p{
    font-size: 28px;
    font-family: Roboto-Regular, Roboto;
    font-weight: 400;
    color: #B3B3B3;
    line-height: 40px;
  }
}

.plan-list-box:last-child{
  margin-bottom: 0;
  em::after{
    display: none;
  }
}
.calculate-way{
  margin-bottom: 32px;
}
p.pay-detail-tips{
  font-size: 24px;
  color: #FF8500;
  line-height: 36px;
  position: relative;
  &::before{
    content:'';
    position: absolute;
    width: 32px;
    left: -44px;
    top: 0;
    bottom: 0;
    background: url('~@/assets/images/stayPay/tips-icon.png') no-repeat center center;
    background-size: 100%;
  }
}
</style>
